import { Controller } from "@hotwired/stimulus";
import { get, FetchRequest } from "@rails/request.js";

// Connects to data-controller='after-signup'
export default class extends Controller {
  static targets = [
    "stateSelect",
    "citySelect",
    "companySelect",
    "mapContainer",
    "geoMarkersLat",
    "geoMarkersLon",
    "geoMarkersType",
  ];
  static values = {
    cardId: String,
  };

  initialize_map(event) {
    // refresh map container on page load
    document.getElementById("map-container").innerHTML =
      "<div data-after-signup-target='mapContainer' class='h-96 w-full rounded-md'><div class='border-2 border-dashed border-gray-300 rounded-md p-6 h-full'><div class='flex items-center justify-center h-full'><p class='text-gray-700 text-sm font-normal'>Select a city first</p></div></div></div>";

    // get lat and lon of city
    let city_latitude = event.target.selectedOptions[0].dataset.latitude;
    let city_longitude = event.target.selectedOptions[0].dataset.longitude;

    // map tile layer
    var osm = L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
      maxZoom: 19,
      attribution:
        "&copy; <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a>",
    });

    // initialise map
    var map = L.map(this.mapContainerTarget, {
      drawControl: false,
      center: [city_latitude, city_longitude], // [12.96, 77.58],
      zoom: 12,
      layers: [osm],
    });

    // initialise custom icon
    var customIcon = L.Icon.extend({
      options: {
        iconSize: [32, 29],
        iconAnchor: [17, 20],
        popupAnchor: [1, -34],
        shadowSize: [32, 29],
      },
    });

    var cycleIcon = new customIcon({
      iconUrl: "bicycle-marker.png",
    });

    // create feature group and add layer to map
    var drawnItems = new L.FeatureGroup();
    map.addLayer(drawnItems);

    // add draw control
    var drawControl = new L.Control.Draw({
      position: "topright",
      draw: false,
      edit: false,
    });

    map.addControl(drawControl);
    map.options.drawControl = true;

    // enable draw/place marker on click
    map.on("click", function () {
      var polyEdit = new L.Draw.Marker(map, { icon: cycleIcon });
      polyEdit.enable();
    });

    // send marker value to geo markers hidden fields on placement
    map.on("draw:created", (e) => {
      drawnItems.clearLayers();

      this.geoMarkersLatTarget.value = e.layer._latlng.lat;
      this.geoMarkersLonTarget.value = e.layer._latlng.lng;
      this.geoMarkersTypeTarget.value = e.layerType;

      drawnItems.addLayer(e.layer);
    });

    // clear layers if marker is placed/edited
    map.on("draw:drawstart", function (e) {
      drawnItems.clearLayers();
    });
  }

  render_states(event) {
    let country_id = event.target.selectedOptions[0].value;
    let target = this.stateSelectTarget.id;

    get(`/locations/states?target=${target}&country_id=${country_id}`, {
      responseKind: "turbo-stream",
    });
  }

  render_cities(event) {
    let state_id = event.target.selectedOptions[0].value;
    let target = this.citySelectTarget.id;

    get(`/locations/cities?target=${target}&state_id=${state_id}`, {
      responseKind: "turbo-stream",
    });
  }

  render_companies(event) {
    let city_id = event.target.selectedOptions[0].value;
    let target = this.companySelectTarget.id;

    get(`/locations/companies?target=${target}&city_id=${city_id}`, {
      responseKind: "turbo-stream",
    });
  }

  submitEnd(event) {
    if (event.detail.success) {
      let target = this.cardIdValue;

      get(`/refresh-details?target=${target}`, {
        responseKind: "turbo-stream",
      });
    }
  }
}
